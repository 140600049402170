import "./App.css";
import rightImage from "./img/right.jpg";

import logo from "./img/logo.svg";
import lt from "./img/Lnt.png";
import jio from "./img/Jio-Logo 1.png";
import tata from "./img/Component 3.png";
import sup from "./img/Schuko.png";
import lnthover from "./img/lnt-hover.png";
import jiohover from "./img/jio-hover.png";
import tatahover from "./img/tata-hover.png";
import suphover from "./img/sup-hover.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faLock } from "@fortawesome/free-solid-svg-icons";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useState, useEffect } from "react";

import Loader from "./Loader";
import { useLocation } from "react-router-dom";

function App() {
    const [loading, setLoading] = useState(true);

    const location = useLocation(); // Get the current URL and query parameters

    console.log(location);
    //forempty params
    //https://customer.fabx.ai/quote?utm_source=fabx+landing+page&utm_medium=Get+quote

    // Simulate an API call or some loading state
    useEffect(() => {
        setTimeout(() => setLoading(false), 2500); // Stop loading after 3 seconds
    }, []);

    const handleRedirect = (url) => {
        window.open(url, "_blank");
    };

    //redirect widow
    useEffect(() => {
        const redirectPath = "/get-quote";
        const targetDomain = "https://customer.fabx.ai/quote";
        const defaultQueryString = "?utm_source=fabx+landing+page&utm_medium=Get+quote";

        if (location.pathname === redirectPath) {
            // Extract the query string
            const queryString = location.search || defaultQueryString;

            // Redirect to the target domain with the query string (default if empty)
            window.location.href = `${targetDomain}${queryString}`;
        }
    }, [location]);

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div className="row row-design ">
                    <div className="col-md-6 col-sm-12 col-xs-12 d-flex col align-items-center justify-content-center ">
                        <div className="left-main">
                            <img
                                src="https://frigatedevelopment.s3.ap-south-1.amazonaws.com/fabx_logos/Final+Logo+6-12-2024.png"
                                alt="fabx"
                                className="logo"
                                style={{ width: "280px" }}
                            />
                            <h1 className="header-txt typing-animation ">Welcome to new era of Manufacturing.</h1>
                            <p className="parah-text">
                                FabX by Frigate, your platform for seamless digital manufacturing in
                                <b> metal, plastics, rubber,</b> and <b>electronics.</b>
                            </p>

                            <ul className="parah-text">
                                <li>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        size="1x"
                                        color="#012f81"
                                    />
                                    <span style={{ paddingLeft: 5 }}>
                                        Transparent Manufacturing: Raw material to delivery.
                                    </span>
                                </li>
                                <li>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        size="1x"
                                        color="#012f81"
                                    />
                                    <span style={{ paddingLeft: 5 }}>
                                        Accurate Quote: Get quick quotes with DFM support
                                    </span>
                                </li>
                                <li>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        color="#012f81"
                                    />
                                    <span style={{ paddingLeft: 5 }}>
                                        Quality Assurance: FABX assured defect-free parts.
                                    </span>
                                </li>
                            </ul>

                            <p
                                className="parah-text-2 "
                                style={{ marginBottom: "8px" }}>
                                FabX ensures efficiency, quality, and reliability in every order.
                            </p>

                            <div className="container px-0">
                                {/* First Row with one item */}
                                <div className="first-row">
                                    <button
                                        className="custom-button"
                                        onClick={() =>
                                            handleRedirect(
                                                "https://customer-uat.fabx.ai/quote?utm_source=fabx+landing+page&utm_medium=Get+quote"
                                            )
                                        }>
                                        GET AN INSTANT QUOTE
                                    </button>
                                </div>

                                <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                                    <p className="parah-text-2 mb-0">
                                        <FontAwesomeIcon
                                            icon={faLock}
                                            color="#656565"
                                        />
                                        <span className="ps-2">All uploads are secure and confidential.</span>
                                    </p>
                                </div>

                                {/* Second Row with two items */}
                                <div className="second-row">
                                    <button
                                        className="secondary-button"
                                        onClick={() => handleRedirect("https://customer-uat.fabx.ai/login")}>
                                        CUSTOMER LOGIN
                                    </button>
                                    <button
                                        className="secondary-button"
                                        onClick={() => handleRedirect(" https://frigater.fabx.ai")}>
                                        SUPPLIER LOGIN
                                    </button>
                                </div>
                            </div>

                            <div className="d-flex align-items-center mt-2">
                                <p
                                    className="parah-text-2 mb-0"
                                    style={{ width: "170px" }}>
                                    {" "}
                                    Join hundreds of our happy Customers:
                                </p>
                                <div
                                    className="d-flex align-items-center gap-4"
                                    style={{ paddingLeft: 20 }}>
                                    <div className="spon-logo-wrapper">
                                        <img
                                            className="spon-logo img-fluid"
                                            src={tata}
                                            alt="Tata"
                                        />

                                        <img
                                            className="spon-logo-hover img-fluid"
                                            src={tatahover}
                                            alt="Tata"
                                        />
                                    </div>
                                    <div className="spon-logo-wrapper">
                                        <img
                                            className="spon-logo img-fluid"
                                            src={lt}
                                            alt="LNT"
                                        />
                                        <img
                                            className="spon-logo-hover img-fluid"
                                            src={lnthover}
                                            alt="LNT"
                                        />
                                    </div>
                                    <div className="spon-logo-wrapper">
                                        <img
                                            className="spon-logo img-fluid"
                                            src={sup}
                                            alt="Sup"
                                        />
                                        <img
                                            className="spon-logo-hover img-fluid"
                                            src={suphover}
                                            alt="Sup"
                                        />
                                    </div>
                                    <div className="spon-logo-wrapper">
                                        <img
                                            className="spon-logo img-fluid"
                                            src={jio}
                                            alt="Jio"
                                        />
                                        <img
                                            className="spon-logo-hover img-fluid"
                                            src={jiohover}
                                            alt="Jio"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12 d-none d-md-block">
                        <img
                            src={rightImage}
                            alt="Right"
                            className="rightImage"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default App;
